$fontFamilyMain: "Roboto";
$fontFamilyAlt_r: "exo";

$primary-color: rgb(17,18,34); // text
$alt-bg-color: #edeff3; //
$alt-bg-color-2: #f9f9fb; // menu
$activeColor: #f10648; // links, buttons
$fontSizeBase: 14px;
$fontSizeBaseLiheHeight: 24px;
$font-stack: $fontFamilyMain, Helvetica, Arial, sans-serif;
$font-stackAlt_r: $fontFamilyAlt_r, Helvetica, Arial, sans-serif;

// font -family
$font: 400 #{$fontSizeBase}/#{$fontSizeBaseLiheHeight} #{$font-stack};
$fontAlt_r: 400 #{$fontSizeBase}/#{$fontSizeBaseLiheHeight} #{$font-stackAlt_r};


/* mixins */
@mixin simpleText() {
    font-size: $fontSizeBase;
    font-weight: 300;
    line-height: $fontSizeBaseLiheHeight;
}
@mixin btn($color, $backgroundColor) {
    color: $color;
    padding: 10px 0;
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    background-color: $backgroundColor;
}
