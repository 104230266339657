@import 'config';

$iconsColor: #b3b3b3;

.main-content {
    background-color: $alt-bg-color;
}

.projects-item {
    margin-bottom: 49px;
    svg {
        fill: $iconsColor;
    }
    .sozi-icon {
        display: inline-flex;
        height: 45px;
        align-items: center;
        padding: 0 9px;
        vertical-align: top;
    }
    .projects-address br {
        display: none;
    }
    .projects-image {
        height: 173px;
        a {
            display: block;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
        }
    }
    .projects-title {
        font-family: $fontFamilyAlt_r;
        font-weight: 300;
        font-size: 20px;
        a {
            color: #111222;
        }
    }
    .projects-deadline {
        font-size: 16px;
        color: $activeColor;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 7px;
        font-weight: 400;
    }
    .projects-deadline--keys {
        margin-right: 10px;
    }
    .projects-info {
        padding: 25px 15px 0 15px;
        box-sizing: border-box;
    }
    .projects-address {
        font-size: 12px;
        color: #85838c;
        line-height: 20px;
        margin-bottom: 7px;
        > div {
            display: flex;
        }
        svg {
            min-width: 10px;
            margin-right: 10px;
            margin-top: 3px;
        }
    }
    .project-row {
        margin-top: 23px;
        .button {
            margin-right: 12px;
        }
    }
}
